import axios from 'axios'
import qs from 'qs'

import { getToken } from '@/services/AuthHeader'

const API_URL = 'https://test-api.glimpse.ie/'

class DataService {
  getLocations() {
    return this.fetchData(
      'locations',
      {
        with_device_id: true
      }
    )
  }

  getFootfall(params) {
    return this.fetchData('footfall', params)
  }

  fetchData(endpoint, params) {
    console.log(
      'Querying endpoint ' + endpoint + ' with params: ' + JSON.stringify(params)
    )

    const url_params = qs.stringify(
      {
        api_key: getToken(),
        metric: endpoint,
        // TODO: This can't exist like this...
        org: 'fields',
        ...params
      },
      {
        arrayFormat: 'comma',  // We want to CSV any arrays in the params.
      }
    )

    const shouldCache = true
    let hash = null

    if (shouldCache) {
      hash = JSON.stringify(url_params) + 'a'
      if (localStorage.getItem(hash)) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 100);
        }).then(() => {
          return JSON.parse(localStorage.getItem(hash))
        });
      }
    }

    return axios.get(
      API_URL + 'query?' + url_params,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    ).then(response => {
      console.log("Data fetched successfully.")
      if (shouldCache) {
        localStorage.setItem(hash, JSON.stringify(response.data))
      }

      return response.data
    }).catch(error => {
      console.log("Error fetching data:")
      console.log(error);
    })
  }
}

export default new DataService()
