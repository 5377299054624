<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DaypartColumnChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        // This gives space between bars:
        stroke: {
          colors: ['transparent'],
          width: 40
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: true
        },
        xaxis: {
          labels: {
            show: false
          }
        }
      },
    }
  },
  computed: {
    chartDataSeries() {
      let series = [
        {
          name: 'Morning',
          data: [{
            x: 'Morning',
            y: 0
          }],
        },
        {
          name: 'Midday',
          data: [{
            x: 'Midday',
            y: 0
          }],
        },
        {
          name: 'Evening',
          data: [{
            x: 'Evening',
            y: 0
          }],
        },
      ]

      if (this.chartData) {
        this.chartData.forEach((e) => {
          const currentHour = parseInt(moment(e._time).format('H'))

          if (currentHour < 12) {
            series[0].data[0].y += e.count
          } else if (currentHour < 14) {
            series[1].data[0].y += e.count
          } else {
            series[2].data[0].y += e.count
          }
        });
      }

      return series
    }
  }
}
</script>
