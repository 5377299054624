<template>
  <div>
    <apexchart
      type="donut"
      :options="parsedChartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SimpleBarChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'pie',
          id: 'Footfall 3',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        labels: [],
      },
    }
  },
  computed: {
    parsedChartOptions() {
      // Need a deep copy:
      let chartOptions = JSON.parse(
        JSON.stringify(this.chartOptions)
      );

      chartOptions.labels = Array();
      if (this.chartData) {
        this.chartData.forEach((e) => {
          chartOptions.labels.push(
            moment(e._time).format('ddd, Do MMM')
          )
        });
      }

      return chartOptions
    },

    chartDataSeries() {
      // First format the time nicely:
      let series = Array()

      if (this.chartData) {
        this.chartData.forEach((e) => {
          series.push(e.count)
        });
      }

      return series
    }
  }
}
</script>
