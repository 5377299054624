<template>
  <p class="font-weight-bold pl-4 display-2 text-primary">
    {{ parsedChartData }}%
  </p>
</template>

<script>
export default {
  name: 'MalePercentageSingleValue',
  props: [
    'chartData'
  ],

  data() {
    return {
    }
  },
  computed: {
    parsedChartData() {
      let perc = null;

      if (this.chartData) {
        this.chartData.forEach((e) => {
          if (e.gender == 'male') {
            perc = e.footfall_percentage
          }
        });
      }

      return Math.round(perc)
    },
  }
}
</script>
