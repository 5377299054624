<template>
  <div>
    <apexchart
      type="area"
      :options="parsedChartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SimpleBarChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'area',
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          id: 'Footfall 2',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        markers: {
          size: 0
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        },
      },
    }
  },
  computed: {
    parsedChartOptions() {
      // Need a deep copy:
      let chartOptions = JSON.parse(
        JSON.stringify(this.chartOptions)
      );

      chartOptions.xaxis.categories = Array();
      if (this.chartData) {
        this.chartData.forEach((e) => {
          chartOptions.xaxis.categories.push(
            moment(e._time).format('ddd, Do MMM')
          )
        });
      }

      return chartOptions
    },

    chartDataSeries() {
      // First format the time nicely:
      let series = [
        {
          name: "footfall-1-series",
          data: Array(),
        },
      ];

      if (this.chartData) {
        this.chartData.forEach((e) => {
          series[0].data.push(e.count)
        });
      }

      return series
    }
  }
}
</script>
