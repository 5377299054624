export function authHeader() {
  /**
   * Adds the auth header for JWTs.
   * TODO: Decide if this is the right way to do this auth?
   */
  let user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return {
      Authorization: 'Bearer ' + user.token
    }
  } else {
    return {}
  }
}

export function getToken() {
  console.log('getting token....');

  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return user.token;
  } else {
    return 'No Token Set!';
  }
}
