<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WeeklyColumnChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '65%',
            endingShape: 'rounded',
            borderRadius: 20,
          }
        },
        grid: {
          padding: {
            left: -100,
            right: -120,
            top: -60
          }
        },
        // This gives space between bars:
        stroke: {
          colors: ['transparent'],
          width: 40
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          offsetY: 50,
          style: {
            fontSize: 'bigger',
            fontWeight: 'bold',
          },
        },
        tooltip: {
          followCursor: true,
        },
        legend: {
          show: true
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          show: false
        }
      },
    }
  },
  computed: {
    chartDataSeries() {
      let series = []

      if (this.chartData) {
        let currentSeries = {}
        let seriesName = null

        this.chartData.forEach((e, i) => {
          if (Object.keys(currentSeries).length == 0) {
            seriesName = 'W/C ' + moment(e._time).format('ddd, Do MMM')
            currentSeries = {
              x: 'Week',
              y: 0
            }
          }

          currentSeries.y += e.count

          if ((i+1) % 7 == 0) {
            series.push({
              data: Array()
            })

            series[series.length - 1].name = seriesName
            series[series.length - 1].data.push(currentSeries)

            currentSeries = {}
          }
        });
      }

      return series
    }
  }
}
</script>
