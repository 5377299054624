<template>
  <div>
    <apexchart
      type="pie"
      :options="parsedChartOptions"
      :series="parsedChartDataSeries"
      />
  </div>
</template>

<script>
export default {
  name: 'MiddayDemographicPieChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'pie',
          id: 'Footfall 3',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        labels: [],
        legend: {
          position: 'bottom'
        }
      },
    }
  },
  computed: {
    parsedChartOptions() {
      // Need a deep copy:
      let chartOptions = JSON.parse(
        JSON.stringify(this.chartOptions)
      );

      let data = this.getPeriodData()

      if (data) {
        chartOptions.series = Array();
        chartOptions.labels = Array();

        Object.keys(data).forEach((e) => {
          if (e != 'time') {
            chartOptions.labels.push(e)
            chartOptions.series.push(data[e])
          }
        });
      }

      return chartOptions
    },
    parsedChartDataSeries() {
      let series = []
      let data = this.getPeriodData()

      if (data) {
        Object.keys(data).forEach((e) => {
          if (e != 'time') {
            series.push(data[e])
          }
        });
      }

      return series
    },
  },
  methods: {
    getPeriodData() {
      let resp = null;

      if (this.chartData) {
        this.chartData.forEach((e) => {
          if (e.time == 'Midday') {
            resp = e
          }
        });
      }

      return resp
    }
  }
}
</script>
