<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
export default {
  name: 'AgeGroupPercentageColumnChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + '%'
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: true
          },
          y: {
            formatter: function(val) {
              return val + '%'
            }
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          labels: {
            show: true
          }
        }
      },
    }
  },
  computed: {
    chartDataSeries() {
      let series = [
        {
          'data': [],
          'name': ''
        }
      ]

      if (this.chartData) {
        let total = 0;
        this.chartData.forEach((e) => {
          total += parseInt(e.count)
        });

        this.chartData.forEach((e) => {
          series[0].data.push({
            x: e.age,
            y: Math.round(100 * (parseInt(e.count) / total))
          });
        });
      }

      return series
    }
  }
}
</script>
