<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DaySplitColumnChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: true
        },
        xaxis: {
          labels: {
            show: true
          }
        }
      },
    }
  },
  computed: {
    chartDataSeries() {
      let series = []

      if (this.chartData) {
        let currentData = Array()
        let currentWeekName = null

        this.chartData.forEach((e, i) => {
          if ( currentData.length == 0 ) {
            // Hacky but we want to store the week day start
            currentWeekName = moment(e._time).format('ddd, Do MMM')
          }

          currentData.push({
            x: moment(e._time).format('dddd'),
            y: e.count
          })

          if ((i+1) % 7 == 0) {
            series.push({
              name: currentWeekName,
              data: currentData
            })
            //series[series.length - 1].name = currentSeries.x + ' sder'
            //series[series.length - 1].data.push(currentSeries)

            currentData = Array()
          }
        });
      }

      return series
    }
  }
}
</script>
