<template>
  <p class="pl-4 display-2">
    {{ parsedChartData }}
  </p>
</template>

<script>
export default {
  name: 'SimpleSingleValue',
  props: [
    'chartData'
  ],

  data() {
    return {
    }
  },
  computed: {
    parsedChartData() {
      let total = 0

      if (this.chartData) {
        this.chartData.forEach((e) => {
          total += e.count
        });
      }

      return total
    }
  }
}
</script>
