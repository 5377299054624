<template>
  <p class="lead pt-3 pl-3">{{ chart.chartTitle }}</p>

  <div
    v-if="loading"
    class="h-100 d-flex justify-content-center align-items-center">
    <ProgressSpinner
      animationDuration="1.5s"
      strokeWidth="3"
      style="width:30%;height:30%"
    />
  </div>
  <div v-else>
    <SimpleBarChart
      v-if="chart.chartType == 'bar'"
      :chartData="chartData"
      />
    <SimpleLineChart
      v-else-if="chart.chartType == 'line'"
      :chartData="chartData"
      />
    <SimpleAreaChart
      v-else-if="chart.chartType == 'area'"
      :chartData="chartData"
      />
    <SimplePieChart
      v-else-if="chart.chartType == 'pie'"
      :chartData="chartData"
      />
    <SimpleDonutChart
      v-else-if="chart.chartType == 'donut'"
      :chartData="chartData"
      />
    <SimpleColumnChart
      v-else-if="chart.chartType == 'column'"
      :chartData="chartData"
      />
    <SimpleDataTable
      v-else-if="chart.chartType == 'table'"
      :chartData="chartData"
      />
    <SimpleSingleValue
      v-else-if="chart.chartType == 'singleValue'"
      :chartData="chartData"
      />
    <WeeklyColumnChart
      v-else-if="chart.chartType == 'weeklyColumn'"
      :chartData="chartData"
      />
    <PercentageSingleValue
      v-else-if="chart.chartType == 'percentageSingleValue'"
      :chartData="chartData"
      />
    <DaypartColumnChart
      v-else-if="chart.chartType == 'daypartColumn'"
      :chartData="chartData"
      />
    <WeekpartColumnChart
      v-else-if="chart.chartType == 'weekpartColumn'"
      :chartData="chartData"
      />
    <DaySplitColumnChart
      v-else-if="chart.chartType == 'daySplitColumn'"
      :chartData="chartData"
      />
    <DeviceComparisonTable
      v-else-if="chart.chartType == 'deviceComparisonTable'"
      :chartData="chartData"
      />
    <HourByDayLineChart
      v-else-if="chart.chartType == 'hourByDayLineChart'"
      :chartData="chartData"
      />
    <MalePercentageSingleValue
      v-else-if="chart.chartType == 'malePercentageSingleValue'"
      :chartData="chartData"
      />
    <FemalePercentageSingleValue
      v-else-if="chart.chartType == 'femalePercentageSingleValue'"
      :chartData="chartData"
      />
    <AgeGroupPercentageColumnChart
      v-else-if="chart.chartType == 'ageGroupPercentageColumn'"
      :chartData="chartData"
      />
    <MorningDemographicPieChart
      v-else-if="chart.chartType == 'morningDemographicPie'"
      :chartData="chartData"
      />
    <MiddayDemographicPieChart
      v-else-if="chart.chartType == 'middayDemographicPie'"
      :chartData="chartData"
      />
    <EveningDemographicPieChart
      v-else-if="chart.chartType == 'eveningDemographicPie'"
      :chartData="chartData"
      />
    <span v-else>NotImplemented</span>
  </div>
</template>

<script>
import AgeGroupPercentageColumnChart from '@/components/charts/AgeGroupPercentageColumnChart'
import DaypartColumnChart from '@/components/charts/DaypartColumnChart'
import DaySplitColumnChart from '@/components/charts/DaySplitColumnChart'
import DeviceComparisonTable from '@/components/charts/DeviceComparisonTable'
import FemalePercentageSingleValue from '@/components/charts/FemalePercentageSingleValue'
import HourByDayLineChart from '@/components/charts/HourByDayLineChart'
import MalePercentageSingleValue from '@/components/charts/MalePercentageSingleValue'
import MorningDemographicPieChart from '@/components/charts/MorningDemographicPieChart'
import MiddayDemographicPieChart from '@/components/charts/MiddayDemographicPieChart'
import EveningDemographicPieChart from '@/components/charts/EveningDemographicPieChart'
import PercentageSingleValue from '@/components/charts/PercentageSingleValue'
import SimpleAreaChart from '@/components/charts/SimpleAreaChart'
import SimpleBarChart from '@/components/charts/SimpleBarChart'
import SimpleColumnChart from '@/components/charts/SimpleColumnChart'
import SimpleDataTable from '@/components/charts/SimpleDataTable'
import SimpleDonutChart from '@/components/charts/SimpleDonutChart'
import SimpleLineChart from '@/components/charts/SimpleLineChart'
import SimplePieChart from '@/components/charts/SimplePieChart'
import SimpleSingleValue from '@/components/charts/SimpleSingleValue'
import WeeklyColumnChart from '@/components/charts/WeeklyColumnChart'
import WeekpartColumnChart from '@/components/charts/WeekpartColumnChart'

import DataService from '@/services/DataService'

export default {
  name: 'GlimpseChart',
  components: {
    AgeGroupPercentageColumnChart,
    DaypartColumnChart,
    DaySplitColumnChart,
    DeviceComparisonTable,
    EveningDemographicPieChart,
    FemalePercentageSingleValue,
    HourByDayLineChart,
    PercentageSingleValue,
    MalePercentageSingleValue,
    MorningDemographicPieChart,
    MiddayDemographicPieChart,
    SimpleAreaChart,
    SimpleBarChart,
    SimpleColumnChart,
    SimpleDataTable,
    SimpleDonutChart,
    SimpleLineChart,
    SimplePieChart,
    SimpleSingleValue,
    WeeklyColumnChart,
    WeekpartColumnChart,
  },
  props: [
    'chart'
  ],

  data() {
    return {
      loading: true,
      chartData: null
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      const self = this

      DataService.fetchData(
        this.chart.endpoint,
        this.chart.params
      ).then(r => {
        self.chartData = r
        self.loading = false
      })
    }
  },

  mounted() {
    this.fetchData()
  },

  watch: {
    chart: function() {
      console.log('watch run')
      this.fetchData()
    }
  }

}
</script>
