<template>
  <div>
    <apexchart
      type="bar"
      :options="parsedChartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SimpleBarChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
        },
      }
    }
  },
  computed: {
    parsedChartOptions() {
      // Need a deep copy:
      let chartOptions = JSON.parse(
        JSON.stringify(this.chartOptions)
      );

      chartOptions.xaxis.categories = Array();
      if (this.chartData) {
        this.chartData.forEach((e) => {
          chartOptions.xaxis.categories.push(
            moment(e._time).format('ddd, Do MMM')
          )
        });
      }

      return chartOptions
    },

    chartDataSeries() {
      // First format the time nicely:
      let series = [
        {
          name: "footfall-1-series",
          data: Array(),
        },
      ];

      if (this.chartData) {
        this.chartData.forEach((e) => {
          series[0].data.push(e.count)
        });
      }

      return series
    }
  }
}
</script>
