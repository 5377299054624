<template>
  <p
    class="font-weight-bold text-center pl-4 display-2"
    :class="getDynamicClass">
    {{ parsedChartData }}%
  </p>
</template>

<script>
export default {
  name: 'PercentageSingleValue',
  props: [
    'chartData'
  ],

  data() {
    return {
    }
  },
  computed: {
    parsedChartData() {
      let firstTotal = 0
      let secondTotal = 0

      if (this.chartData) {
        // Short-circuit for simple values:
        if (typeof(this.chartData) == 'number') {
          return Math.round(this.chartData)
        }

        // This specifically sums up half the datapoints and gets the % change
        // to the next half of the datapoints. This is niche.
        this.chartData.forEach((e, i) => {
          if (i < this.chartData.length / 2) {
            firstTotal += e.count
          } else {
            secondTotal += e.count
          }
        });
      }

      return Math.round(100 * ((secondTotal - firstTotal) / firstTotal))
    },
    getDynamicClass() {
      if (this.parsedChartData >= 0) {
        return 'text-success prepend-plus-sign'
      } else {
        return 'text-danger'
      }
    }
  }
}
</script>

<style scoped>
.prepend-plus-sign::before {
  content: '+';
  font-size: smaller;
}
</style>
