<template>
  <div>
    <DataTable
      :value="parsedChartData"
      responsiveLayout="scroll"
      :rowClass="getRowClass"
      >
      <Column
        v-for="col of parsedChartDataColumns"
        :field="col.field"
        :header="col.header"
        :key="col.key"
        :bodyClass="getColumnClass(col.field)"
        />
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SimpleDataTable',
  // TODO: Theme
  // TODO: This sort of relies on the first day being Monday. Not really, but for it to make sense.
  props: [
    'chartData'
  ],

  data() {
    return {
    }
  },
  computed: {
    parsedChartData() {
      let parsedChartData = []

      if (this.chartData) {
        let row = {}

        this.chartData.forEach((e) => {
          // This is a scrappy method...
          // First lets add the "Week" key if the current row is empty:
          if (Object.keys(row).length == 0) {
            row["Week"] = moment(e._time).format('Do MMM')
          }

          // Then lets add the day of week we're looking at:
          row[moment(e._time).format('dddd')] = e.count

          // Then lets save it if we've got a full week:
          if (Object.keys(row).length == 8) {
            parsedChartData.push(row);
            row = {}
          }
        });

        // Now we want a % change row:
        if (parsedChartData.length == 2) {
          let percChangeRow = {}

          Object.keys(parsedChartData[0]).forEach((e) => {
            // Special Case:
            if (e == "Week") {
              percChangeRow['Week'] = '% Change';
            } else {
              percChangeRow[e] = Math.round(100 * ((parsedChartData[1][e] - parsedChartData[0][e]) / parsedChartData[0][e])) + '%'
            }
          });

          parsedChartData.push(percChangeRow)
        }
      }

      return parsedChartData
    },
    parsedChartDataColumns() {
      let cols = []

      Object.keys(this.parsedChartData[0]).forEach((e) => {
        cols.push({
          field: e,
          header: e
        })
      });

      return cols
    }
  },
  methods: {
    getRowClass(row) {
      if (row.Week.indexOf('%') == -1) {
        return null;
      } else {
        return 'percChangeRow'
      }
    },
    getColumnClass(value) {
      if (this.parsedChartData.length != 3) {
        return null
      }

      if (this.parsedChartData[2][value].indexOf('% Change') != -1) {
        return null
      } else if (this.parsedChartData[2][value].indexOf('-') == -1) {
        return 'change-increase';
      } else {
        return 'change-decrease';
      }
    },
  }
}
</script>

<style>
.percChangeRow .change-increase {
  background-color: rgba(20, 225, 20, 0.6) !important;
}

.percChangeRow .change-decrease {
  background-color: rgba(225, 20, 20, 0.6) !important;
}
</style>
