<template>
  <div>
    <!-- selectionMode can be Single or Range or Multiple! -->
    Time:
    <Calendar
      v-model="localSelectedTimerange"
      selectionMode="range" 
      dateFormat="dd/mm/yy"
      :inline="false"
      @date-select="updateSelectedTimerange"
      />
  </div>
</template>

<script>
export default {
  name: 'TimerangeInput',
  components: {},
  props: [],

  data() {
    return {
      loading: true,
      localSelectedTimerange: [],
    }
  },

  methods: {
    updateSelectedTimerange() {
      if (this.localSelectedTimerange[1]) {
        this.$store.commit(
          'searchPrefsStore/setSelectedTimerange',
          this.localSelectedTimerange
        )
      }
    }
  },

  mounted() {
    this.localSelectedTimerange = [
      this.$store.getters['searchPrefsStore/getEarliestDate'].toDate(),
      this.$store.getters['searchPrefsStore/getLatestDate'].toDate()
    ];
  },

}
</script>
