<template>

  <Sidebar
    v-model:visible="sidebarVisible"
    class="p-sidebar-md"
    >
    <p class="lead pt-1 pl-3">Inputs</p>

    <TimerangeInput/>
    <DeviceInput/>
  </Sidebar>

  <Button
    class="sticky-icon"
    icon="pi pi-arrow-right"
    @click="sidebarVisible=true" />

  <vue3-simple-html2pdf
    ref='vue3SimpleHtml2pdf'
    :options="pdfOptions"
    :filename="exportFilename"
    >
    <grid-layout
      v-model:layout="charts"
      :col-num="100"
      :row-height="30"
      :is-resizable="false"
      :is-draggable="false"
      :margin="[25, 20]"
      vertical-compact
      use-css-transforms
      >
      <!--
        Add:
          responsive="true"
        to the grid-layout or else hardcode responsive layouts.
      -->
      <grid-item
        v-for="chart in charts"
        :static="chart.static"
        :x="chart.x"
        :y="chart.y"
        :w="chart.w"
        :h="chart.h"
        :i="chart.i"
        :key="chart.i"
        :is-resizable="false"
        :is-draggable="false"
        :preserveAspectRatio="chart.preserveAspectRatio"
        >
        <GlimpseChart :chart="chart.glimpseChartDetails"/>
      </grid-item>
    </grid-layout>
  </vue3-simple-html2pdf>

  <Button @click="downloadPDF()">Export PDF</Button>

</template>

<script>
import { useToast } from 'vue-toastification'

import GlimpseChart from '@/components/GlimpseChart'
import DeviceInput from '@/components/DeviceInput'
import TimerangeInput from '@/components/TimerangeInput'

export default {
  name: 'Dashboard',
  components: {
    GlimpseChart,
    DeviceInput,
    TimerangeInput,
  },

  data() {
    return {
      sidebarVisible: false,

      pdfOptions: {
        margin: 15,
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: {
          scale: 3
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'l'
        },
      },
      exportFilename: 'test.pdf',
    }
  },
  computed: {
    charts() {
      return [
        {
          i: 0, x: 0, y: 0, w: 25, h: 10,
          static: false, preserveAspectRatio: true,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false
            },
            chartType: 'weeklyColumn',
            chartTitle: 'Weekly Visitors'
          },
        },
        {
          i: 1, x: 25, y: 0, w: 28, h: 5,
          static: false, preserveAspectRatio: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false
            },
            chartType: 'percentageSingleValue',
            chartTitle: 'Footfall Change (Week on Week)'
          },
        },
        /*
        {
          i: 2, x: 0, y: 9, w: 30, h: 8, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: true
            },
            chartType: 'deviceComparisonTable',
            chartTitle: 'Footfall by Location (TODO!)'
          },
        },
        */
        {
          i: 3, x: 53, y: 0, w: 47, h: 8,
          static: false, preserveAspectRatio: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false
            },
            chartType: 'table',
            chartTitle: 'Daily Visitors Breakdown'
          },
        },
        {
          i: 8, x: 25, y: 1, w: 14, h: 5,
          static: false, preserveAspectRatio: false,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false,
              gender: true,
              top_profiles: false
            },
            chartType: 'malePercentageSingleValue',
            chartTitle: 'Male Gender Split'
          },
        },
        {
          i: 9, x: 39, y: 1, w: 14, h: 5,
          static: false, preserveAspectRatio: false,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false,
              gender: true,
              top_profiles: false
            },
            chartType: 'femalePercentageSingleValue',
            chartTitle: 'Female Gender Split'
          },
        },
        {
          i: 11, x: 0, y: 3, w: 18, h: 9, static: false,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              split_locations: false,
              gender: false,
              time_of_day: true,
              top_profiles: true
            },
            chartType: 'morningDemographicPie',
            chartTitle: 'Morning Demographic Profiles'
          },
        },
        {
          i: 12, x: 18, y: 3, w: 18, h: 9, static: false,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              split_locations: false,
              gender: false,
              time_of_day: true,
              top_profiles: true
            },
            chartType: 'middayDemographicPie',
            chartTitle: 'Midday Demographic Profiles'
          },
        },
        {
          i: 13, x: 36, y: 3, w: 18, h: 9, static: false,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              split_locations: false,
              gender: false,
              time_of_day: true,
              top_profiles: true
            },
            chartType: 'eveningDemographicPie',
            chartTitle: 'Evening Demographic Profiles'
          },
        },
        {
          i: 6, x: 54, y: 2, w: 46, h: 16,
          static: false, preserveAspectRatio: true,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false
            },
            chartType: 'daySplitColumn',
            chartTitle: 'Daily Visitors'
          },
        },
        {
          i: 4, x: 0, y: 4, w: 27, h: 10,
          static: false, preserveAspectRatio: true,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1h',
              split_device: false
            },
            chartType: 'daypartColumn',
            chartTitle: 'Activity per Time of Day'
          },
        },
        {
          i: 10, x: 27, y: 4, w: 27, h: 10,
          static: false, preserveAspectRatio: true,

          glimpseChartDetails: {
            endpoint: 'profiles',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false,
              gender: false,
              age: true,
              top_profiles: false
            },
            chartType: 'ageGroupPercentageColumn',
            chartTitle: 'Age Group Percentages'
          },
        },
        {
          i: 5, x: 54, y: 5, w: 26, h: 10,
          static: false, preserveAspectRatio: true,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1h',
              split_device: false
            },
            chartType: 'weekpartColumn',
            chartTitle: 'Weekday/Weekend Split'
          },
        },
        {
          i: 14, x: 80, y: 5, w: 20, h: 5,
          static: false, preserveAspectRatio: false,

          glimpseChartDetails: {
            endpoint: 'loyalty',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_locations: false
            },
            chartType: 'percentageSingleValue',
            chartTitle: 'Return Visitors Per Month'
          },
        },
        {
          i: 7, x: 0, y: 6, w: 54, h: 18,
          static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1h',
              split_locations: false
            },
            chartType: 'hourByDayLineChart',
            chartTitle: 'Visitor Counts by Hour'
          },
        },
      ]
      /*
      exampleCharts: [
        {
          i: 8, x: 1, y: 1, w: 6, h: 5, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'singleValue',
            chartTitle: 'Modular Powered Single Value'
          },
        },
        {
          i: 7, x: 0, y: 0, w: 30, h: 8, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'table',
            chartTitle: 'Modular Powered Data Table'
          },
        },
        {
          // vue-grid-layout Layout:
          i: 0, x: 0, y: 1, w: 10, h: 10, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'bar',
            chartTitle: 'Modular Powered Bar Chart'
          },
        },
        {
          i: 1, x: 12, y: 1, w: 15, h: 15, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'line',
            chartTitle: 'Simple Line Chart Component'
          },
        },
        {
          i: 2, x: 4, y: 1, w: 10, h: 10, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'area',
            chartTitle: 'Simple Area Chart Component'
          },
        },
        {
          i: 3, x: 0, y: 1, w: 6, h: 6, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'pie',
            chartTitle: 'Simple Pie Chart Component'
          },
        },
        {
          i: 4, x: 15, y: 1, w: 7, h: 9, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'donut',
            chartTitle: 'Simple Donut Chart Component'
          },
        },
        {
          i: 5, x: 0, y: 3, w: 10, h: 10, static: false,

          glimpseChartDetails: {
            endpoint: 'footfall',
            params: {
              device_ids: this.deviceIDs,
              start: this.earliestDate,
              end: this.latestDate,
              span: '1d',
              split_device: false
            },
            chartType: 'column',
            chartTitle: 'Simple Column Chart Component'
          },
        },
        */
    },
    deviceIDs() {
      return this.$store.getters['searchPrefsStore/getSelectedDevicesForCharts']
    },
    earliestDate() {
      return this.$store.getters['searchPrefsStore/getEarliestDate'].format('yyyy-MM-DD')
    },
    latestDate() {
      return this.$store.getters['searchPrefsStore/getLatestDate'].format('yyyy-MM-DD')
    },
  },
  methods: {
    downloadPDF() {
      useToast().info('PDF Generating')
      this.$refs.vue3SimpleHtml2pdf.download()
    },
  },
}
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #fff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.vue-grid-item:not(.vue-grid-placeholder):hover {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.vue-grid-item .resizing {
  opacity: 0.2;
}
.vue-grid-item .static {
  background: #fff;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
  no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.sticky-icon {
  position: sticky;
  top: 60px;
  z-index: 100;
}
</style>
