<template>
  <div>
    Selected Devices:
    <MultiSelect
      v-model="localSelectedDevices"
      :options="possibleDevices"
      :loading="loading"
      optionLabel="name"
      :filter="true"
      placeholder="Select Devices..."
      @change="updateSelectedDevices"
      />
  </div>
</template>

<script>
import DataService from '@/services/DataService'

export default {
  name: 'DeviceInput',
  components: {},
  props: [],

  data() {
    return {
      loading: true,
      localSelectedDevices: [],
      possibleDevices: []
    }
  },

  computed: {
    selectedDevices() {
      return this.$store.state.searchPrefsStore.selectedDevices;
    }
  },

  methods: {
    fetchData() {
      this.loading = true;
      const self = this;

      DataService.getLocations().then(r => {
        self.possibleDevices = Array();

        Object.keys(r).forEach((key) => {
          self.possibleDevices.push({
            value: key,
            name: r[key]
          });
        });
        self.loading = false
      })
    },

    updateSelectedDevices(event) {
      this.$store.commit('searchPrefsStore/setSelectedDevices', event.value)
    }
  },

  mounted() {
    this.fetchData()
    this.localSelectedDevices = this.$store.getters['searchPrefsStore/getSelectedDevicesForInput'];
  },

}
</script>
