<template>
  <div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartDataSeries"
      />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DaySplitColumnChart',
  props: [
    'chartData'
  ],

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'line',
          id: 'Footfall 1',
        },
        theme: {
          palette: this.$store.state.userPrefsStore.themeID
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true
        },
        xaxis: {
          labels: {
            show: true
          }
        }
      },
    }
  },
  computed: {
    chartDataSeries() {
      let series = []

      if (this.chartData) {
        let currentData = Array()
        let currentDayName = null

        this.chartData.forEach((e, i) => {
          if ( currentData.length == 0 ) {
            // Hacky but we want to store the week day start
            currentDayName = moment(e._time).format('ddd')
          }

          if (e.count != 0) {
            currentData.push({
              x: moment(e._time).format('HH') + ':00',
              y: e.count
            })
          }

          if ((i+1) % 24 == 0) {
            series.push({
              name: currentDayName,
              data: currentData
            })

            currentData = Array()
          }

        });
      }

      return series
    }
  }
}
</script>
